import { createBrowserRouter } from "react-router-dom";

import App from "./App";
import Dashboard from "./components/common/Dashboard";
import Profile from "./components/forms/Profile";
import ContactList from "./components/forms/ContactList";
import ErrorPage from "./components/common/ErrorPage";
import LocationForm from "./components/forms/LocationForm";
import AxiosExample from "./components/forms/axiosExample";

const router = createBrowserRouter([
  {
    path: "/",
    element: <App />,
    errorElement: <ErrorPage />,
    children: [
      { index: true, element: <Dashboard /> },
      { path: "profile", element: <Profile /> },
      { path: "locations", element: <LocationForm /> },
      { path: "contacts", element: <ContactList /> },
      { path: "axios", element: <AxiosExample /> },
    ],
  },
]);

export default router;
