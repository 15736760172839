import React, { useMemo } from "react";
import { getTables } from "../../services/getTables";
import MaterialReactTable from "material-react-table";

import {
  MDBContainer,
  MDBCard,
  MDBCardHeader,
  MDBCardFooter,
  MDBTooltip,
  MDBSpinner,
} from "mdb-react-ui-kit";

export default function TableMessages() {
  const { data: messages, error } = getTables("messages");
  // console.log("Messages: " + JSON.stringify(messages));

  const columns = useMemo(
    () => [
      {
        header: "ID",
        accessorKey: "messageid",
        size: 140,
      },
      {
        header: "Received",
        accessorKey: "datetime",
      },
      {
        header: "Device",
        accessorKey: "deviceid",
        size: 110,
      },
      {
        header: "Company",
        accessorKey: "companyname",
        size: 140,
      },
      {
        header: "Location",
        accessorKey: "locationname",
      },
      {
        header: "Door1",
        accessorKey: "door1",
        size: 80,
      },
      {
        header: "Door2",
        accessorKey: "door2",
        size: 80,
      },
      {
        header: "Door3",
        accessorKey: "door3",
        size: 80,
      },
    ],
    []
  );

  if (error) {
    return (
      <MDBCard>
        <MDBCardHeader className="text-danger fw-bold m-0">
          Failure to retrieve messages table data: {error.message}
        </MDBCardHeader>
      </MDBCard>
    );
  }

  if (messages === undefined) {
    return (
      <MDBCard>
        <MDBCardHeader className="fw-bold m-0">
          <MDBSpinner size="sm"></MDBSpinner>
          <span className="fw-bold ps-2 m-0">
            Loading table message data...
          </span>
        </MDBCardHeader>
      </MDBCard>
    );
  }

  return (
    <MDBCard>
      <MDBCardHeader className="text-reset fw-bold">
        {" "}
        <MDBTooltip
          tag="a"
          title="Displays the telemetry traffic captured by the IOT sensors."
        >
          Traffic Messages
        </MDBTooltip>
      </MDBCardHeader>
      <MDBContainer fluid className="py-2">
        <MaterialReactTable
          columns={columns}
          data={messages}
          enableColumnActions={false}
          enableColumnFilterModes={true}
          enableColumnFilters={true}
          enablePagination={true}
          enableSorting={true}
          enableBottomToolbar={true}
          enableTopToolbar={true}
          muiTableBodyRowProps={{ hover: true }}
          initialState={{ density: "compact" }}
          enableColumnResizing={true}
          enableColumnDragging={true}
          enableColumnOrdering={true}
          columnResizeMode="onEnd"
          muiTablePaperProps={{
            elevation: 0, //change the mui box shadow
            //customize paper styles
            sx: {
              borderRadius: "0",
              border: "0px",
            },
          }}
        />
      </MDBContainer>

      <MDBCardFooter className="text-muted">
        {messages?.length + " message records"}
      </MDBCardFooter>
    </MDBCard>
  );
}
