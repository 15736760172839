import { isRouteErrorResponse, useRouteError } from "react-router-dom";

const ErrorPage = () => {
  const error = useRouteError();

  if (error) console.log("Error Object: " + JSON.stringify(error, null, 2));

  return (
    <>
      <div className="p-4">
        <h1>Oops...</h1>
        <div>
          {isRouteErrorResponse(error)
            ? "Invalid page error:"
            : "Unexpected application error:"}
        </div>

        <pre>
          <code>{JSON.stringify(error, null, 2)}</code>
        </pre>
      </div>
    </>
  );
};

export default ErrorPage;
