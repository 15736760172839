import React from "react";
import { UserContext } from "../App.js";

import axios from "axios";
import { useQuery, useMutation } from "@tanstack/react-query";

const httpService = (endpoint, method, entity) => {
  const { user } = React.useContext(UserContext);
  const config = {
    headers: {
      TIAuth: user.signInUserSession.idToken.jwtToken,
    },
  };
  const baseURL = "https://api.telemetryinsights.com";

  switch (method) {
    case "get":
      console.log("Get: " + method + ":" + endpoint);
      return useQuery({
        queryKey: [method + endpoint],
        queryFn: async () =>
          axios
            .get(baseURL + endpoint, config)
            .then((response) => response.data),
        staleTime: 1 * 15 * 1000, // 15 seconds
      });
    case "create":
      console.log("Method: ", method + ":" + endpoint + " - " + entity);
      return useMutation({
        mutationFn: (entity) => axios.post(baseURL + endpoint, entity, config),
      });
    case "update":
      console.log("Method: ", method + ":" + endpoint + " - " + entity);
      return useMutation({
        queryFn: async () =>
          axios.patch(baseURL + endpoint + "/" + entity.id, entity, config),
      });
    case "delete":
      console.log("Method: ", method + ":" + endpoint + " - " + entity);
      return useMutation({
        mutationFn: async () =>
          axios.delete(baseURL + endpoint + "/" + entity.messageid, config),
      });
    default:
      return null;
  }
};

export default httpService;
