import React, { useMemo } from "react";
import { getTables } from "../../services/getTables";
import MaterialReactTable from "material-react-table";

import {
  MDBContainer,
  MDBCard,
  MDBCardHeader,
  MDBCardFooter,
  MDBTooltip,
  MDBSpinner,
} from "mdb-react-ui-kit";

export default function TableUsers() {
  const { data: users, error } = getTables("users");
  // console.log("Users: " + JSON.stringify(users));

  const columns = useMemo(
    () => [
      {
        header: "Contact ID",
        accessorKey: "id",
        size: 240,
      },
      {
        header: "Title",
        accessorKey: "title",
        size: 140,
      },
      {
        header: "Given Name",
        accessorKey: "givenname",
        size: 140,
      },
      {
        header: "Family Name",
        accessorKey: "familyname",
        size: 140,
      },
      {
        header: "Phone",
        accessorKey: "phone",
      },
    ],
    []
  );

  if (error) {
    return (
      <MDBCard>
        <MDBCardHeader className="text-danger fw-bold m-0">
          Failure to retrieve contacts table data: {error.message}
        </MDBCardHeader>
      </MDBCard>
    );
  }

  if (users === undefined) {
    return (
      <MDBCard>
        <MDBCardHeader className="fw-bold m-0">
          <MDBSpinner size="sm"></MDBSpinner>
          <span className="fw-bold ps-2 m-0">
            Loading table contacts data...
          </span>
        </MDBCardHeader>
      </MDBCard>
    );
  }

  return (
    <MDBCard>
      <MDBCardHeader className="text-reset fw-bold">
        {" "}
        <MDBTooltip
          tag="a"
          title="Displays the telemetry traffic captured by the IOT sensors."
        >
          Company Contacts
        </MDBTooltip>
      </MDBCardHeader>
      <MDBContainer fluid className="py-2">
        <MaterialReactTable
          columns={columns}
          data={users}
          enableColumnActions={false}
          enableColumnFilterModes={true}
          enableColumnFilters={true}
          enablePagination={true}
          enableSorting={true}
          enableBottomToolbar={true}
          enableTopToolbar={true}
          muiTableBodyRowProps={{ hover: true }}
          initialState={{ density: "compact" }}
          enableColumnResizing={true}
          enableColumnDragging={true}
          enableColumnOrdering={true}
          columnResizeMode="onEnd"
          muiTablePaperProps={{
            elevation: 0, //change the mui box shadow
            //customize paper styles
            sx: {
              borderRadius: "0",
              border: "0px",
            },
          }}
        />
      </MDBContainer>

      <MDBCardFooter className="text-muted">
        {users?.length + " Contacts records"}
      </MDBCardFooter>
    </MDBCard>
  );
}
