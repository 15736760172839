import {
  MDBContainer,
  MDBCard,
  MDBCardHeader,
  MDBCardFooter,
  MDBTooltip,
} from "mdb-react-ui-kit";

let map = null;
let marker1 = null;
let marker2 = null;

async function initMap() {
  const { Map } = await google.maps.importLibrary("maps");
  const { AdvancedMarkerElement, PinElement } = await google.maps.importLibrary(
    "marker"
  );

  const shape = {
    coords: [1, 1, 1, 20, 18, 20, 18, 1],
    type: "poly",
  };

  const image = {
    url: "https://developers.google.com/maps/documentation/javascript/examples/full/images/beachflag.png",
    // This marker is 20 pixels wide by 32 pixels high.
    size: new google.maps.Size(20, 32),
    // The origin for this image is (0, 0).
    origin: new google.maps.Point(0, 0),
    // The anchor for this image is the base of the flagpole at (0, 32).
    anchor: new google.maps.Point(0, 32),
  };

  const svgMarker = {
    path: "M-1.547 12l6.563-6.609-1.406-1.406-5.156 5.203-2.063-2.109-1.406 1.406zM0 0q2.906 0 4.945 2.039t2.039 4.945q0 1.453-0.727 3.328t-1.758 3.516-2.039 3.070-1.711 2.273l-0.75 0.797q-0.281-0.328-0.75-0.867t-1.688-2.156-2.133-3.141-1.664-3.445-0.75-3.375q0-2.906 2.039-4.945t4.945-2.039z",
    fillColor: "blue",
    fillOpacity: 0.6,
    strokeWeight: 0,
    rotation: 0,
    scale: 2,
    anchor: new google.maps.Point(0, 20),
  };

  map = new Map(document.getElementById("map"), {
    center: { lat: 33.8556812, lng: -84.3226479 },
    zoom: 7,
    mapId: "TI_MAP_ID", // Map ID is required for advanced markers, using abbreviation of Telemetry Insights.
  });

  const marker1 = new google.maps.marker.AdvancedMarkerElement({
    position: { lat: 34.797422, lng: -83.932423 },
    map,
    title: "Telemetry Insights, LLC - Headquarters",
  });

  const marker2 = new google.maps.marker.AdvancedMarkerElement({
    position: { lat: 33.84823, lng: -84.32852 },
    map,
    title: "Telemetry Insights, LLC - Warehouse",
  });

  // Create an info window to share between markers.
  const infoWindow = new google.maps.InfoWindow();

  // Add a click listener for each marker, and set up the info window.
  /*marker1.addListener("click", () => {
    infoWindow.close();
    infoWindow.setContent(marker1.getTitle());
    infoWindow.open(marker1.getMap(), marker1);
  });

  marker2.addListener("click", () => {
    infoWindow.close();
    infoWindow.setContent(marker2.getTitle());
    infoWindow.open(marker2.getMap(), marker2);
  });*/
}

export default function Maps() {
  initMap();

  return (
    <>
      <MDBCard>
        <MDBCardHeader className="text-reset fw-bold">
          <MDBTooltip tag="a" title="Maps with markers all locations">
            Telemetry Insights, LLC - Locations Map
          </MDBTooltip>
        </MDBCardHeader>
        <MDBContainer
          style={{ height: "50vh" }}
          className="p-2 my-3"
          id="map"
        ></MDBContainer>
        <MDBCardFooter className="text-muted">2 locations Marked</MDBCardFooter>
      </MDBCard>
    </>
  );
}
