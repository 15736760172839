import { useState, useEffect } from "react";
import {
  MDBContainer,
  MDBCard,
  MDBCardHeader,
  MDBCardBody,
  MDBCardTitle,
  MDBCardText,
  MDBBtn,
  MDBRow,
  MDBCol,
  MDBDatepicker,
  MDBSelectDeprecated,
  MDBIcon,
  MDBCollapse,
  MDBTooltip,
} from "mdb-react-ui-kit";
import { ChartDaily, ChartHourly, ChartDateHourly } from "../charts";
import { TableMessages, TableUsers } from "../../components/tables";
import Maps from "../../components/maps/Maps";

export default function Dashboard() {
  const [showShow, setShowShow] = useState(true);
  const toggleShow = () => setShowShow(!showShow);

  return (
    <MDBContainer fluid>
      <MDBRow className="d-flex justify-content-center">
        <MDBCol md="12" className="p-2">
          <MDBCard>
            <MDBCardHeader className="text-reset fw-bold">
              <MDBTooltip tag="a" title="Click to show/hide filters">
                Dashboard Filters
              </MDBTooltip>

              <MDBIcon
                fas
                icon="caret-down"
                className="mx-1"
                style={{ cursor: "pointer" }}
                onClick={toggleShow}
              />
            </MDBCardHeader>
            <MDBCollapse show={showShow}>
              <MDBCardBody>
                <MDBCardTitle>
                  <MDBRow className="d-flex justify-content-center">
                    <MDBCol md="6" className="p-2">
                      <MDBSelectDeprecated
                        label="Locations"
                        multiple
                        data={[
                          {
                            text: "Telemetry Insights HQ",
                            value: 1,
                            selected: true,
                          },
                          {
                            text: "Telemetry Insights Lab",
                            value: 2,
                            selected: true,
                          },
                        ]}
                      />
                    </MDBCol>

                    <MDBCol md="3" className="p-2">
                      <MDBSelectDeprecated
                        label="Date Range"
                        data={[
                          { text: "Today", value: 1 },
                          { text: "Yesterday", value: 2 },
                          { text: "Last 7 days", value: 3, selected: true },
                          { text: "Last 28 days", value: 4 },
                          { text: "Last 90 days", value: 5 },
                        ]}
                      />
                    </MDBCol>
                    <MDBCol md="3" className="p-2">
                      <MDBDatepicker
                        inputLabel="Date"
                        defaultValue="Custom date"
                      />
                    </MDBCol>
                  </MDBRow>
                </MDBCardTitle>
                <MDBCardText className="d-flex justify-content-center">
                  <MDBBtn>Apply Filter</MDBBtn>
                </MDBCardText>
              </MDBCardBody>
            </MDBCollapse>
          </MDBCard>
        </MDBCol>
      </MDBRow>

      <MDBRow className="d-flex justify-content-center">
        <MDBCol sm="6" className="py-3">
          <ChartHourly />
        </MDBCol>

        <MDBCol sm="6" className="py-3">
          <ChartDaily />
        </MDBCol>
      </MDBRow>

      <MDBRow className="d-flex justify-content-center">
        <MDBCol sm="6" className="py-3">
          <ChartDateHourly />
        </MDBCol>

        <MDBCol sm="6" className="py-3">
          <TableMessages />
        </MDBCol>
      </MDBRow>

      <MDBRow className="d-flex justify-content-center">
        <MDBCol sm="12" className="py-3">
          <TableUsers />
        </MDBCol>
      </MDBRow>

      <MDBRow className="d-flex justify-content-center">
        <MDBCol sm="12" className="py-3">
          <Maps />
        </MDBCol>
      </MDBRow>
    </MDBContainer>
  );
}
