import { useState } from "react";
import { CgMaximize, CgMinimize } from "react-icons/cg";
import { getCharts } from "../../services/getCharts";

import {
  MDBContainer,
  MDBCard,
  MDBCardHeader,
  MDBCardFooter,
  MDBChart,
  MDBTooltip,
  MDBSpinner,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
} from "mdb-react-ui-kit";

export default function ChartDaily() {
  const [fullscreenModal, setFullscreenModal] = useState(false);
  const toggleShow = () => setFullscreenModal(!fullscreenModal);

  const chartoptions = {
    scales: {
      y: {
        stacked: true,
        ticks: {
          beginAtZero: true,
        },
      },
      x: {
        stacked: true,
      },
    },
  };

  const { data: chartDaily, error } = getCharts("daily");
  // console.log("chartData: " + JSON.stringify(chartDaily));

  if (error) {
    return (
      <MDBCard>
        <MDBCardHeader className="text-danger fw-bold m-0">
          Failure to retrieve daily charting data: {error.message}
        </MDBCardHeader>
      </MDBCard>
    );
  }

  if (chartDaily === undefined) {
    return (
      <MDBCard>
        <MDBCardHeader className="fw-bold m-0">
          <MDBSpinner size="sm"></MDBSpinner>
          <span className="fw-bold ps-2 m-0">Loading daily chart data...</span>
        </MDBCardHeader>
      </MDBCard>
    );
  }

  const chartDailySeries = {
    labels: chartDaily.map((obj) => obj.message["daily"]),
    datasets: [
      {
        backgroundColor: "rgba(255, 99, 132, 1)",
        borderColor: "rgba(255,99,132,1)",
        data: chartDaily.map((obj) => obj.message["sensor1"]),
        label: "Door1",
      },
      {
        backgroundColor: "rgba(54, 162, 235, 1)",
        borderColor: "rgba(54, 162, 235, 1)",
        data: chartDaily.map((obj) => obj.message["sensor2"]),
        label: "Door2",
      },
      {
        backgroundColor: "rgba(153, 102, 255, 1)",
        borderColor: "rgba(153, 102, 255, 1)",
        data: chartDaily.map((obj) => obj.message["sensor3"]),
        label: "Door3",
      },
    ],
  };

  return (
    <>
      <MDBCard>
        <MDBCardHeader className="text-reset fw-bold">
          <div className="d-flex">
            <div className="w-75">
              <MDBTooltip
                tag="a"
                title="Displays the telemetry traffic captured by the IOT sensors, grouped
            daily."
              >
                Daily Traffic
              </MDBTooltip>
            </div>

            <div className="w-25 d-flex justify-content-end align-items-center">
              <CgMaximize
                color="rgb(10,10,10,0.8)"
                opacity={10}
                size={18}
                onClick={toggleShow}
                cursor={"pointer"}
              />
            </div>
          </div>
        </MDBCardHeader>
        <MDBContainer fluid>
          <MDBChart type="bar" data={chartDailySeries} options={chartoptions} />
        </MDBContainer>

        <MDBCardFooter className="px=2">
          {chartDailySeries.datasets[0].data.reduce(
            (accumulator, currentValue) => accumulator + currentValue
          ) +
            chartDailySeries.datasets[1].data.reduce(
              (accumulator, currentValue) => accumulator + currentValue
            ) +
            chartDailySeries.datasets[2].data.reduce(
              (accumulator, currentValue) => accumulator + currentValue
            ) +
            " traffic records"}
        </MDBCardFooter>
      </MDBCard>

      <MDBModal
        tabIndex="-1"
        show={fullscreenModal}
        setShow={setFullscreenModal}
      >
        <MDBModalDialog size="fullscreen">
          <MDBModalContent>
            <MDBModalHeader className="text-reset fw-bold">
              <MDBModalTitle>
                <MDBTooltip
                  tag="a"
                  title="Displays the telemetry traffic captured by the IOT sensors, grouped
            daily."
                >
                  Daily Traffic
                </MDBTooltip>
              </MDBModalTitle>

              <CgMinimize
                color="rgb(10,10,10,0.8)"
                opacity={10}
                size={22}
                onClick={toggleShow}
                cursor={"pointer"}
              />
            </MDBModalHeader>
            <MDBModalBody>
              <MDBContainer fluid>
                <MDBChart
                  type="bar"
                  data={chartDailySeries}
                  options={chartoptions}
                />
              </MDBContainer>
            </MDBModalBody>
            <MDBModalFooter className="px=2">
              {chartDailySeries.datasets[0].data.reduce(
                (accumulator, currentValue) => accumulator + currentValue
              ) +
                chartDailySeries.datasets[1].data.reduce(
                  (accumulator, currentValue) => accumulator + currentValue
                ) +
                chartDailySeries.datasets[2].data.reduce(
                  (accumulator, currentValue) => accumulator + currentValue
                ) +
                " traffic records"}
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
    </>
  );
}
