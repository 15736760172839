import React, { useState, useEffect } from "react";
import { useIdleTimer } from "react-idle-timer";
import { Auth } from "aws-amplify";
import {
  MDBModal,
  MDBModalBody,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalFooter,
  MDBBtn,
} from "mdb-react-ui-kit";

export default function Inactivity() {
  const timeout = 900_000;
  const promptBeforeIdle = 30_000;

  const [remaining, setRemaining] = useState(timeout);
  const [openModal, setOpenModal] = useState(false);

  const onIdle = () => {
    setOpenModal(false);
    Auth.signOut();
  };

  const onActive = () => {
    setOpenModal(false);
  };

  const onPrompt = () => {
    setOpenModal(true);
  };

  const { getRemainingTime, activate } = useIdleTimer({
    onIdle,
    onActive,
    onPrompt,
    timeout,
    promptBeforeIdle,
    throttle: 500,
  });

  useEffect(() => {
    const interval = setInterval(() => {
      setRemaining(Math.ceil(getRemainingTime() / 1000));
    }, 500);

    return () => {
      clearInterval(interval);
    };
  });

  const handleStillHere = () => {
    activate();
  };

  return (
    <>
      <MDBModal show={openModal} tabIndex="-1">
        <MDBModalDialog>
          <MDBModalContent>
            <MDBModalHeader>
              <MDBModalTitle>Inactivity Detected</MDBModalTitle>
              <MDBBtn
                className="btn-close"
                color="none"
                onClick={handleStillHere}
              ></MDBBtn>
            </MDBModalHeader>
            <MDBModalBody>
              <p>Are you still here?</p>
              <p>Logging out in {remaining} seconds</p>
            </MDBModalBody>

            <MDBModalFooter>
              <MDBBtn id="basicModal" color="primary" onClick={handleStillHere}>
                Yes
              </MDBBtn>
            </MDBModalFooter>
          </MDBModalContent>
        </MDBModalDialog>
      </MDBModal>
      ;
    </>
  );
}
