import React, { useState, useRef } from "react";
import { Outlet } from "react-router-dom";

// AWS libraries for authentication and internationalization
import "@aws-amplify/ui-react/styles.css";
import awsExports from "./aws-exports";
import { Auth, I18n } from "aws-amplify";
import {
  Authenticator,
  useTheme,
  View,
  Image,
  Text,
} from "@aws-amplify/ui-react";

import { MDBContainer } from "mdb-react-ui-kit";
import { SideBar, NavBar, Footer, Inactivity } from "./components/common";

// Initialize Cognito authorization configuration
Auth.configure(awsExports);

// Use internationalization in order to change the value on authenticator
I18n.setLanguage("en");
const dict = {
  en: {
    Username: "Email",
  },
};
I18n.putVocabularies(dict);

export const UserContext = React.createContext();
export default function Layout() {
  const components = {
    Header() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Image
            alt="Telemetry Insights logo"
            src="https://app.telemetryinsights.com/logo-transparent-4000x1240.png"
          />
        </View>
      );
    },

    Footer() {
      const { tokens } = useTheme();

      return (
        <View textAlign="center" padding={tokens.space.large}>
          <Text color={tokens.colors.neutral[80]}>
            &copy;2023, Telemetry Insights - All Rights Reserved
          </Text>
        </View>
      );
    },
  };

  const [modeOpen, setModeOpen] = useState(false);
  const sideNavContent = useRef(null);

  return (
    <Authenticator
      socialProviders={["google", "amazon", "facebook"]}
      components={components}
    >
      {({ signOut, user }) => (
        <UserContext.Provider value={{ signOut, user }}>
          <NavBar toggleSideNav={() => setModeOpen(!modeOpen)} />
          <SideBar toggleSideNav={(e) => setModeOpen(e)} modeOpen={modeOpen} />
          <MDBContainer fluid ref={sideNavContent}>
            <Outlet />
          </MDBContainer>
          <Footer />
          <Inactivity />
        </UserContext.Provider>
      )}
    </Authenticator>
  );
}
