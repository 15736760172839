import React from "react";

import IMask from "imask";
import {
  MDBInput,
  MDBBtn,
  MDBModal,
  MDBModalDialog,
  MDBModalContent,
  MDBModalHeader,
  MDBModalTitle,
  MDBModalBody,
  MDBModalFooter,
  MDBSelect,
} from "mdb-react-ui-kit";

function addressParts(address, part, shorthName) {
  let i = 0;
  while (i < address.length) {
    if (address[i].types[0] === part) {
      if (shorthName) {
        return address[i].short_name;
      } else {
        return address[i].long_name;
      }
    }
    i++;
  }
  return "";
}

class LocationForm extends React.Component {
  constructor(props) {
    super(props);
    this.state = this.initialState();
    this.handlePlaceSelect = this.handlePlaceSelect.bind(this);
    this.handleChange = this.handleChange.bind(this);
    this.handleChangeSelect = this.handleChangeSelect.bind(this);
    this.handleSubmit = this.handleSubmit.bind(this);
    this.handleModal = this.handleModal.bind(this);
    this.autocomplete = null;
  }

  initialState() {
    return {
      streetNumber: "",
      streetAddress1: "",
      streetAddress2: "",
      city: "",
      county: "",
      state: "",
      zipCode: "",
      country: "",
      googleMapLink: "",
      lat: "0",
      lng: "0",
      utcOffsetMinutes: "",
      fullAddress: "",
      basicModal: false,
      selectValue: [
        { value: "AL", text: "Alabama" },
        { value: "AK", text: "Alaska" },
        { value: "AZ", text: "Arizona" },
        { value: "AR", text: "Arkansas" },
        { value: "CA", text: "California" },
        { value: "CO", text: "Colorado" },
        { value: "CT", text: "Connecticut" },
        { value: "DE", text: "Delaware" },
        { value: "DC", text: "District Of Columbia" },
        { value: "FL", text: "Florida" },
        { value: "GA", text: "Georgia" },
        { value: "HI", text: "Hawaii" },
        { value: "ID", text: "Idaho" },
        { value: "IL", text: "Illinois" },
        { value: "IN", text: "Indiana" },
        { value: "IA", text: "Iowa" },
        { value: "KS", text: "Kansas" },
        { value: "KY", text: "Kentucky" },
        { value: "LA", text: "Louisiana" },
        { value: "ME", text: "Maine" },
        { value: "MD", text: "Maryland" },
        { value: "MA", text: "Massachusetts" },
        { value: "MI", text: "Michigan" },
        { value: "MN", text: "Minnesota" },
        { value: "MS", text: "Mississippi" },
        { value: "MO", text: "Missouri" },
        { value: "MT", text: "Montana" },
        { value: "NE", text: "Nebraska" },
        { value: "NV", text: "Nevada" },
        { value: "NH", text: "New Hampshire" },
        { value: "NJ", text: "New Jersey" },
        { value: "NM", text: "New Mexico" },
        { value: "NY", text: "New York" },
        { value: "NC", text: "North Carolina" },
        { value: "ND", text: "North Dakota" },
        { value: "OH", text: "Ohio" },
        { value: "OK", text: "Oklahoma" },
        { value: "OR", text: "Oregon" },
        { value: "PA", text: "Pennsylvania" },
        { value: "RI", text: "Rhode Island" },
        { value: "SC", text: "South Carolina" },
        { value: "SD", text: "South Dakota" },
        { value: "TN", text: "Tennessee" },
        { value: "TX", text: "Texas" },
        { value: "UT", text: "Utah" },
        { value: "VT", text: "Vermont" },
        { value: "VA", text: "Virginia" },
        { value: "WA", text: "Washington" },
        { value: "WV", text: "West Virginia" },
        { value: "WI", text: "Wisconsin" },
        { value: "WY", text: "Wyoming" },
      ],
    };
  }

  componentDidMount() {
    this.autocomplete = new google.maps.places.Autocomplete(
      document.getElementById("autocomplete"),
      {}
    );

    this.autocomplete.addListener("place_changed", this.handlePlaceSelect);
  }

  handleChange(event) {
    console.log(event);
    this.setState({ [event.target.placeholder]: event.target.value });
    if (event.target.placeholder === "zipCode") {
      IMask(document.getElementById("zip"), { mask: "00000-0000" });
    }
  }

  handleChangeSelect(value) {
    this.setState({
      state: value.value,
      selectValue: this.state.selectValue.map((option) =>
        option.value === value.value
          ? { ...option, defaultSelected: true }
          : { ...option, defaultSelected: false }
      ),
    });

    this.forceUpdate();
  }

  handleModal(event) {
    event.preventDefault();
    this.setState({ basicModal: !this.state.basicModal });
  }

  handlePlaceSelect() {
    let addressObject = this.autocomplete.getPlace();
    let address = addressObject.address_components;
    // console.log("addressObject: ", addressObject);

    this.setState({
      streetNumber: addressParts(address, "street_number", true),
      streetName: addressParts(address, "route", true),
      streetAddress1: addressParts(address, "street_number", true)
        ? addressParts(address, "street_number", true) +
          " " +
          addressParts(address, "route", true)
        : addressParts(address, "route", true),
      streetAddress2: addressParts(address, "subpremise", true),
      city: addressParts(address, "locality", true),
      county: addressParts(address, "administrative_area_level_2", true),
      state: addressParts(address, "administrative_area_level_1", true),
      zipCode: addressParts(address, "postal_code_suffix", true)
        ? addressParts(address, "postal_code", true) +
          "-" +
          addressParts(address, "postal_code_suffix", true)
        : addressParts(address, "postal_code", true),
      zipCopdeSuffix: addressParts(address, "postal_code_suffix", true),
      country: addressParts(address, "country", true),
      fullAddress: addressObject.formatted_address,
      googleMapLink: addressObject.url,
      lat: addressObject.geometry.location.lat(),
      lng: addressObject.geometry.location.lng(),
      utcOffsetMinutes: addressObject.utc_offset_minutes,
    });

    this.handleChangeSelect({
      value: addressParts(address, "administrative_area_level_1", true),
      text: addressParts(address, "administrative_area_level_1", false),
    });
  }

  handleSubmit(event) {
    event.preventDefault();
    this.setState({ basicModal: !this.state.basicModal });
    console.log("handleSubmit: ", this.state);
    // this.props.dispatch(addLocation(this.state));
  }

  render() {
    return (
      <>
        <h1>Add New Location</h1>
        <form onSubmit={this.handleSubmit}>
          <MDBInput
            id="autocomplete"
            label="Address Search"
            className="input-field"
          />
          <MDBInput
            id="streetAddress1"
            label="Street Address1"
            value={this.state.streetAddress1}
            placeholder={"streetAddress1"}
            onChange={this.handleChange}
            className="my-3"
          />
          <MDBInput
            label="Street Address2"
            value={this.state.streetAddress2}
            placeholder={"streetAddress2"}
            onChange={this.handleChange}
            className="my-3"
          />
          <MDBInput
            label="City"
            value={this.state.city}
            placeholder={"city"}
            onChange={this.handleChange}
            className="my-3"
          />

          <MDBSelect
            id="state"
            label="State"
            onValueChange={this.handleChangeSelect}
            data={this.state.selectValue}
            search
            preventFirstSelection
            className="my-3"
          />

          <MDBInput
            id="zip"
            label="Zip Code"
            value={this.state.zipCode}
            placeholder={"zipCode"}
            onChange={this.handleChange}
            className="my-3"
          />

          <MDBBtn tag="a" color="secondary" href="/">
            Cancel
          </MDBBtn>
          <MDBBtn type="submit" onSubmit={this.handleSubmit} className="mx-3">
            Submit
          </MDBBtn>

          <MDBModal show={this.state.basicModal} tabIndex="-1">
            <MDBModalDialog>
              <MDBModalContent>
                <MDBModalHeader>
                  <MDBModalTitle>Valid Address Parsed</MDBModalTitle>
                  <MDBBtn
                    className="btn-close"
                    color="none"
                    onClick={this.handleModal}
                  ></MDBBtn>
                </MDBModalHeader>
                <MDBModalBody>
                  <p className="m-0">{this.state.streetAddress1}</p>
                  <p className="m-0">{this.state.streetAddress2}</p>
                  <p className="m-0">{this.state.city}</p>
                  <p className="m-0">{this.state.county}</p>
                  <p className="m-0">{this.state.state}</p>
                  <p className="m-0">{this.state.zipCode}</p>
                  <p className="m-0">{this.state.country}</p>
                  <p className="m-0">
                    {this.state.lat + " | " + this.state.lng}
                  </p>
                  <p className="m-0">{this.state.utcOffsetMinutes}</p>
                </MDBModalBody>

                <MDBModalFooter>
                  <MDBBtn
                    id="basicModal"
                    color="primary"
                    onClick={this.handleModal}
                  >
                    Close
                  </MDBBtn>
                </MDBModalFooter>
              </MDBModalContent>
            </MDBModalDialog>
          </MDBModal>
        </form>
      </>
    );
  }
}

export default LocationForm;
