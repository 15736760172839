import React from "react";
import { UserContext } from "../App";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const getCharts = (option) => {
  const { user } = React.useContext(UserContext);
  const config = {
    headers: {
      TIAuth: user.signInUserSession.idToken.jwtToken,
    },
  };

  return useQuery({
    queryKey: ["chart/" + option],
    queryFn: async () =>
      axios
        .get("https://api.telemetryinsights.com/chart?option=" + option, config)
        .then((response) => response.data),
    staleTime: 1 * 15 * 1000, // 15 seconds
  });
};

export { getCharts };
