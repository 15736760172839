import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

import { z } from "zod";
import { zodResolver } from "@hookform/resolvers/zod";
import { useForm } from "react-hook-form";
import { MDBInput, MDBBtn } from "mdb-react-ui-kit";
import IMask from "imask";

const schema = z.object({
  name: z.string().min(3),
  age: z.coerce.number().min(10),
  uuid: z.string().uuid(),
  phone: z.string().length(14),
  url: z.string().url(),
  email: z.string().email(),
});

export default function Profile() {
  useEffect(() => {
    const phone = document.getElementById("phone");
    const phoneMaskOptions = {
      mask: "(000) 000-0000",
    };
    IMask(phone, phoneMaskOptions);

    const uuid = document.getElementById("uuid");
    const uuidMaskOptions = {
      mask: "********-****-****-****-************",
    };
    IMask(uuid, uuidMaskOptions);
  }, []);

  const navigate = useNavigate();

  const {
    register,
    handleSubmit,
    formState: { errors, isValid },
  } = useForm({ resolver: zodResolver(schema), mode: "onBlur" });

  const onSubmit = (data) => console.log(data);

  return (
    <form onSubmit={handleSubmit(onSubmit)}>
      <div className="m-3">
        <MDBInput label="Name" {...register("name")} />
        {errors.name && <p className="text-danger">{errors.name.message}</p>}
      </div>

      <div className="m-3">
        <MDBInput label="Age" {...register("age")} />
        {errors.age && <p className="text-danger">{errors.age.message}</p>}
      </div>

      <div className="m-3">
        <MDBInput label="UUID" id="uuid" {...register("uuid")} />
        {errors.uuid && <p className="text-danger">{errors.uuid.message}</p>}
      </div>

      <div className="m-3">
        <MDBInput
          label="Phone Number"
          id="phone"
          {...register("phone")}
          type="tel"
        />
        {errors.phone && <p className="text-danger">{errors.phone.message}</p>}
      </div>

      <div className="m-3">
        <MDBInput label="URL" {...register("url")} type="URL" />
        {errors.url && <p className="text-danger">{errors.url.message}</p>}
      </div>

      <div className="m-3">
        <MDBInput label="eMail Address" {...register("email")} type="email" />
        {errors.email && <p className="text-danger">{errors.email.message}</p>}
      </div>

      <div className="m-3">
        <MDBBtn
          tag="a"
          color="secondary"
          onClick={(event) => {
            navigate("/");
          }}
        >
          Cancel
        </MDBBtn>
        <MDBBtn disabled={!isValid} type="submit" className="mx-3">
          Submit
        </MDBBtn>
      </div>
    </form>
  );
}
