import React from "react";
import { UserContext } from "../App.js";

import axios from "axios";
import { useQuery } from "@tanstack/react-query";

const getTables = (option) => {
  const { user } = React.useContext(UserContext);
  const config = {
    headers: {
      TIAuth: user.signInUserSession.idToken.jwtToken,
    },
  };

  return useQuery({
    queryKey: ["table/" + option],
    queryFn: async () =>
      axios
        .get("https://api.telemetryinsights.com/" + option, config)
        .then((response) => response.data),
    staleTime: 1 * 15 * 1000, // 15 seconds
  });
};

export { getTables };
