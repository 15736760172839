import { useEffect, useState } from "react";
import httpService from "../../services/httpService";
import {
  MDBContainer,
  MDBCard,
  MDBCardHeader,
  MDBTooltip,
  MDBSpinner,
} from "mdb-react-ui-kit";

const ContactList = () => {
  // const [users, setUsers] = useState([]);
  const { status, data: users, error } = httpService("/messages", "get");

  /*useEffect(() => {
    if (status === "isSucces") {
      console.log("This Data: ", data);
      setUsers(data);
      console.log("This Users: ", users);
    }
  }, []);
  // console.log("Users: ", status, users);

  const deleteContact = (user) => {
    const originalUsers = [...users];
    setUsers(users.filter((u) => u.messageid !== user.messageid));

    console.log("Delete User: ", user);

    useEffect(() => {
      httpService("/users", "delete", user).catch((err) => {
        setError(err.message);
        setUsers(originalUsers);
      });
    }, []);
  };*/

  const deleteContact = (user) => {
    const { status, error, mutate } = httpService("/users", "delete", user);
  };

  const addContact = () => {
    const originalUsers = [...users];
    const newUser = { messageid: 0, name: "Stephen Harris" };
    setUsers([newUser, ...users]);

    httpService("/users", "create", newUser)
      .then(({ data: savedUser }) => setUsers([savedUser, ...users]))
      .catch((err) => {
        setError(err.message);
        setUsers(originalUsers);
      });
  };

  const updateContact = (user) => {
    const originalUsers = [...users];
    const updatedUser = { ...user, name: user.name + "!" };
    setUsers(
      users.map((u) => (u.messageid === user.messageid ? updatedUser : u))
    );

    httpService("/users", "update", updatedUser).catch((err) => {
      setError(err.message);
      setUsers(originalUsers);
    });
  };

  return (
    <MDBCard className="my-3">
      {status === "loading" ? (
        <MDBCardHeader className="text-reset fw-bold">
          <MDBSpinner size="sm"></MDBSpinner>
          <span className="fw-bold ps-2 m-0">
            Loading table contacts data...
          </span>
        </MDBCardHeader>
      ) : status === "error" ? (
        <MDBCardHeader className="text-danger fw-bold m-0">
          Failure to retrieve messages table data: {error.message}
        </MDBCardHeader>
      ) : (
        <>
          <MDBCardHeader className="text-reset fw-bold">
            <MDBTooltip
              tag="a"
              title="Displays the telemetry traffic captured by the IOT sensors."
            >
              {users.length} Traffic Messages updated @{" "}
              {new Date(users.ts).toLocaleTimeString()}
            </MDBTooltip>
          </MDBCardHeader>

          <MDBContainer fluid className="py-2">
            <button className="btn btn-primary my-3" onClick={addContact}>
              Add
            </button>
            <ul className="list-group">
              {users &&
                users.map((user) => (
                  <li
                    key={user.messageid}
                    className="list-group-item d-flex justify-content-between"
                  >
                    {user.messageid}
                    <div>
                      <button
                        className="btn btn-outline-secondary mx-1"
                        onClick={() => updateContact(user)}
                      >
                        Update
                      </button>
                      <button
                        className="btn btn-outline-danger"
                        onClick={() => deleteContact(user)}
                      >
                        Delete
                      </button>
                    </div>
                  </li>
                ))}
            </ul>
          </MDBContainer>
        </>
      )}
    </MDBCard>
  );
};

export default ContactList;
