import React, { useState, useRef, useEffect } from "react";
import { useNavigate } from "react-router-dom";

import {
  MDBSideNav,
  MDBSideNavMenu,
  MDBSideNavItem,
  MDBSideNavLink,
  MDBSideNavCollapse,
  MDBIcon,
} from "mdb-react-ui-kit";

export default function SideBar(props) {
  const navigate = useNavigate();

  const [modeCollapse1, setModeCollapse1] = useState(false);
  const [modeCollapse2, setModeCollapse2] = useState(false);

  const sideNavContent = useRef(null);
  const [container, setContainer] = useState();

  useEffect(() => {
    setContainer(sideNavContent.current);
  }, []);

  return (
    <>
      <MDBSideNav
        mode="over"
        isOpen={props?.modeOpen}
        contentRef={container}
        absolute
        getOpenState={(e) => props?.toggleSideNav(e)}
      >
        <div className="d-flex justify-content-center py-1">
          <img
            id="TI-logo"
            src="https://app.telemetryinsights.com/logo-transparent-232x72.png"
            alt="Telemetry Insights Logo"
            draggable="false"
          />
        </div>

        <hr className="m-0 bg-success" />

        <MDBSideNavMenu>
          <MDBSideNavItem>
            <MDBSideNavLink
              onClick={(event) => {
                props?.toggleSideNav(false);
                navigate("/");
              }}
            >
              <MDBIcon fas icon="chart-column" className="fa-fw me-3" />
              Dashboards
            </MDBSideNavLink>
          </MDBSideNavItem>

          <MDBSideNavItem>
            <MDBSideNavLink
              icon="angle-down"
              shouldBeExpanded={modeCollapse1}
              onClick={() => setModeCollapse1(!modeCollapse1)}
            >
              <MDBIcon fas icon="globe" className="fa-fw me-3" />
              Company
            </MDBSideNavLink>
            <MDBSideNavCollapse show={modeCollapse1}>
              <MDBSideNavLink>
                <MDBIcon fas icon="user" className="fa-fw me-3" />
                Contacts
              </MDBSideNavLink>
              <MDBSideNavLink>
                <MDBIcon
                  fas
                  icon="mobile-screen-button"
                  className="fa-fw me-3"
                />
                Devices
              </MDBSideNavLink>
            </MDBSideNavCollapse>
          </MDBSideNavItem>

          <MDBSideNavItem>
            <MDBSideNavLink
              icon="angle-down"
              shouldBeExpanded={modeCollapse2}
              onClick={() => setModeCollapse2(!modeCollapse2)}
            >
              <MDBIcon fas icon="building" className="fa-fw me-3" />
              Locations
            </MDBSideNavLink>
            <MDBSideNavCollapse show={modeCollapse2}>
              <MDBSideNavLink
                onClick={(event) => {
                  props?.toggleSideNav(false);
                  navigate("/contacts");
                }}
              >
                <MDBIcon fas icon="user" className="fa-fw me-3" />
                Contacts
              </MDBSideNavLink>
              <MDBSideNavLink
                onClick={(event) => {
                  props?.toggleSideNav(false);
                  navigate("/axios");
                }}
              >
                <MDBIcon
                  fas
                  icon="mobile-screen-button"
                  className="fa-fw me-3"
                />
                Devices
              </MDBSideNavLink>
              <MDBSideNavLink
                onClick={(event) => {
                  props?.toggleSideNav(false);
                  navigate("/locations");
                }}
              >
                <MDBIcon fas icon="building" className="fa-fw me-3" />
                Locations
              </MDBSideNavLink>
            </MDBSideNavCollapse>
          </MDBSideNavItem>

          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon fas icon="mobile-screen-button" className="fa-fw me-3" />
              Devices
            </MDBSideNavLink>
          </MDBSideNavItem>

          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon fas icon="print" className="fa-fw me-3" />
              Charts & Reports
            </MDBSideNavLink>
          </MDBSideNavItem>

          <MDBSideNavItem>
            <MDBSideNavLink>
              <MDBIcon fas icon="database" className="fa-fw me-3" />
              Configuration
            </MDBSideNavLink>
          </MDBSideNavItem>
        </MDBSideNavMenu>
      </MDBSideNav>
    </>
  );
}
