import React from "react";
import { UserContext } from "../../../App.js";
import { useNavigate } from "react-router-dom";

import {
  MDBNavbar,
  MDBNavbarToggler,
  MDBNavbarItem,
  MDBSideNavLink,
  MDBNavbarNav,
  MDBDropdown,
  MDBDropdownToggle,
  MDBDropdownMenu,
  MDBDropdownItem,
  MDBIcon,
} from "mdb-react-ui-kit";

export default function NavBar(props) {
  const navigate = useNavigate();
  const { signOut, user } = React.useContext(UserContext);
  // console.log("The User: ", user);

  return (
    <>
      <MDBNavbar className="navbar">
        <MDBNavbarToggler onClick={props?.toggleSideNav}>
          <MDBIcon fas icon="bars" />
        </MDBNavbarToggler>

        <MDBNavbarNav fullWidth={false} className="mb-2 mb-lg-0">
          <MDBNavbarItem>
            <MDBDropdown>
              <MDBDropdownToggle
                outline
                rounded
                color="primary"
                className="px-2 mx-2"
              >
                Welcome {user.attributes.email}
                <img
                  src="https://lh3.googleusercontent.com/a/AGNmyxYIDphXOUIYyidLvXPFx3bliQj7qcFesZh1Fnqz4Q=s96-c"
                  className="rounded-circle px-1"
                  height="35"
                  draggable="false"
                  loading="lazy"
                />
              </MDBDropdownToggle>
              <MDBDropdownMenu responsive="end">
                <MDBDropdownItem>
                  <MDBSideNavLink
                    onClick={(event) => {
                      navigate("/profile");
                    }}
                  >
                    <MDBIcon fas icon="id-card" className="fa-lg mx-3" />
                    My profile
                  </MDBSideNavLink>
                </MDBDropdownItem>
                <MDBDropdownItem>
                  <MDBSideNavLink>
                    <MDBIcon fas icon="gear" className="fa-fw fa-lg mx-3" />
                    Settings
                  </MDBSideNavLink>
                </MDBDropdownItem>
                <hr className="m-0 bg-success" />
                <MDBDropdownItem>
                  <MDBSideNavLink onClick={signOut}>
                    <MDBIcon
                      fas
                      icon="arrow-right-from-bracket"
                      className="fa-fw fa-lg mx-3"
                    />
                    Logout
                  </MDBSideNavLink>
                </MDBDropdownItem>
              </MDBDropdownMenu>
            </MDBDropdown>
          </MDBNavbarItem>
        </MDBNavbarNav>
      </MDBNavbar>
    </>
  );
}
